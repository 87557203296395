import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import OpenModal from 'src/components/OpenModal'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { ContentHeader } from './style'

const CartaoDeCreditoSemAnuidade = () => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()
  const urlMobile = 'https://inter-co.onelink.me/Qyu7/jzd1g4bo'

  return (
    <ContentHeader
      className='d-flex justify-content-center justify-content-lg-between'
      role='img'
      aria-label='Tela de celular mostrando Super App ao lado de cartão de crédito Inter'
    >
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          {
            width > WIDTH_MD &&
              <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
                <ImageWebp
                  arrayNumbers={[ 0, 282, 356, 500 ]}
                  arrayNumbersHeight={[ 0, 238, 303, 450 ]}
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/image-credit-cart/image.webp'
                  altDescription='Celular e cartão Inter'
                />
              </div>
          }
          <div className='col-12 col-md-6 col-xl-5 offset-lg-1 offset-xl-2 px-xl-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-50 text-grayscale--500 fw-600 d-block mb-3'>
              Cartão de crédito sem anuidade com programa de pontos
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-0'>
              Seu novo cartão já vem habilitado para compras no débito e pode ter a função crédito, sujeito a análise.
              É sem anuidade e você ainda acumula pontos para trocar por milhas, cashback e muito mais.
            </p>
            {
              width < WIDTH_MD
                ? (
                  <a
                    href={urlMobile}
                    title='Pedir meu cartão'
                    className='btn btn-orange--extra btn--lg rounded-2 fw-600 mw-100 text-none mt-4'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_04',
                        section_name: 'Cartão de crédito sem anuidade com programa de pontos',
                        element_action: 'click button',
                        element_name: 'Pedir meu cartão',
                        redirect_url: urlMobile,
                      })
                    }}
                  >
                    Pedir meu cartão
                  </a>
                )
              : (
                <OpenModal
                  to='open-your-account-form'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_04',
                      section_name: 'Cartão de crédito sem anuidade com programa de pontos',
                      element_action: 'click button',
                      element_name: 'Pedir meu cartão',
                    })
                  }}
                >
                  <button
                    className='btn btn-orange--extra btn--lg rounded-2 fw-600 mw-100 text-none mt-4'
                    title='Pedir meu cartão'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_04',
                        section_name: 'Cartão de crédito sem anuidade com programa de pontos',
                        element_action: 'click button',
                        element_name: 'Pedir meu cartão',
                      })
                    }}
                  >
                    Pedir meu cartão
                  </button>
                </OpenModal>
              )
            }
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default CartaoDeCreditoSemAnuidade
