import React from 'react'

import * as S from './style'

import Dollar from 'inter-frontend-svgs/lib/orangeds/MD/credit'
import Boleto from 'inter-frontend-svgs/lib/orangeds/MD/deposit-by-boleto'
import Globe from 'inter-frontend-svgs/lib/orangeds/MD/globe'
import Investiments from 'inter-frontend-svgs/lib/orangeds/MD/investments'
import DepositByCheck from 'inter-frontend-svgs/lib/orangeds/MD/money-up'
import User from 'inter-frontend-svgs/lib/orangeds/MD/user-account'
import { IconProps } from 'src/components/Layout/Footer/Footer.interface'
import { orange } from 'src/styles/colors'

import slugify from 'slugify'
import data from './assets/data/data.json'
interface ICardsProps {
  icon: string;
  text: string;
}

const TodosOsServicosQueVocePrecisa = () => {
  const icons: IconProps = {
    depositByCheck: <DepositByCheck color={orange.extra} width={24} height={24} />,
    poupanca: <Investiments color={orange.extra} width={24} height={24} />,
    envioRemessa: <Boleto color={orange.extra} width={24} height={24} />,
    dollar: <Dollar color={orange.extra} width={24} height={24} />,
    globe: <Globe color={orange.extra} width={24} height={24} />,
    pessoaFisica: <User color={orange.extra} width={24} height={24} />,
  }

  const cardData: ICardsProps[] = data
  return (
    <S.ContentHeader className='bg-orange--extra'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-4 col-xl-5 offset-lg-1 order-md-last px-xl-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-50 text-white fw-600 d-block text-center text-md-left mb-3 mb-md-0'>
              Todos os serviços que você precisa, online e sem burocracia!
            </h2>
          </div>
          <div className='col-12 col-md-8 col-lg-7 col-xl-6'>
            <S.Cards>
              {cardData.map((card: ICardsProps) => (
                <S.Card key={slugify(card.text)} className='d-flex align-items-center'>
                  <div className='mr-4'>
                    {icons[card.icon]}
                  </div>
                  <div>
                    <h3
                      dangerouslySetInnerHTML={{ __html: card.text }}
                      className='fs-14 lh-17 fs-lh-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-700 text-gray--700 mb-0'
                    />
                  </div>
                </S.Card>
              ))}
            </S.Cards>
          </div>
        </div>
      </div>
    </S.ContentHeader>
  )
}

export default TodosOsServicosQueVocePrecisa
