import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import CartaoDeCreditoSemAnuidade from './sections/cartao-de-credito-sem-anuidade/_index'
import ComoAbrirSuaContaDigitalPeloCelular from './sections/como-abrir-sua-conta-digital-pelo-celular/_index'
import Hero from './sections/header/_index'
import IndiqueAmigosParaOInter from './sections/indique-amigos-para-o-inter/_index'
import MaisQueUmBancoDigital from './sections/mais-que-um-banco-digital/_index'
import OMelhorPixEstaAqui from './sections/o-melhor-pix-esta-aqui/_index'
import PixPagamentosTransferencias from './sections/pix-pagamentos-e-transferencias/_index'
import PorqueNaoCobramosTarifas from './sections/porque-nao-cobramos-tarifas/_index'
import TodosOsServicosQueVocePrecisa from './sections/todos-os-servicos-que-voces-precisam/_index'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

const ContaDigitalPF = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <OMelhorPixEstaAqui />
        <PixPagamentosTransferencias />
        <CartaoDeCreditoSemAnuidade />
        <TodosOsServicosQueVocePrecisa />
        <ComoAbrirSuaContaDigitalPeloCelular />
        <MaisQueUmBancoDigital />
        <IndiqueAmigosParaOInter />
        <PorqueNaoCobramosTarifas />
      </Layout>
    </Wrapper>
  )
}

export default ContaDigitalPF
