import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Button, Section } from './style'

const OMelhorPixEstaAqui = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <Section>
      <img src='https://central-imagens.bancointer.com.br/images-without-small-versions/conta-digital-pessoa-fisica-dobra-2/image.webp' alt='Imagem que ilustra como é a área de Pix do aplicativo do Inter.' />
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12 col-md-6' />
          <div className='col-12 col-md-6 margin-mobile'>
            <h2 className='fs-32 lh-40 fs-xl-48 lh-xl-50 fw-600 text-grayscale--500'>
              O melhor Pix está aqui
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400 mt-3'>
              Somos a única instituição financeira com o <strong>Pix nota A há 24 meses consecutivos</strong>, avaliado pelo Índice de Qualidade de Serviço do Banco Central (IQS).
            </p>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 fw-500 text-grayscale--400'>
              O resultado do IQS é calculado através dos índices de reclamações, disponibilidade e tempo de resposta.
            </p>
            <Button
              href='https://inter.co/pix/'
              target='_blank' rel='noreferrer'
              title='Conheça o Pix do Inter'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'O melhor Pix está aqui',
                  element_action: 'click button',
                  element_name: 'Conheça o Pix do Inter',
                })
              }}
            > Conheça o Pix do Inter
            </Button>
          </div>
        </div>
      </div>
    </Section>

  )
}

export default OMelhorPixEstaAqui
