import React, { useLayoutEffect, useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import ImageWebp from 'src/components/ImageWebp'
import OpenModal from 'src/components/OpenModal'
import Stepper from './Stepper/_index'

import stepperData from './assets/data/data.json'
import { ContentHeader, Link } from './style'

const ComoAbrirSuaContaDigitalPeloCelular = () => {
  const [ isMobile, setIsMobile ] = useState(true)
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://inter-co.onelink.me/Qyu7/jzd1g4bo'

  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <ContentHeader className='d-flex justify-content-center justify-content-lg-between'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 d-flex justify-content-center'>
            <ImageWebp
              arrayNumbers={[ 239, 332, 376, 519 ]}
              arrayNumbersHeight={[ 294, 408, 513, 739 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/screen-app-two-phones/image.webp'
              altDescription='Tela de celular mostrando o Super App Inter'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 offset-lg-1 offset-xl-2'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-50 text-grayscale--500 fw-600 d-block mb-0'>
              Como abrir sua conta digital pelo celular
            </h2>
            <Stepper data={stepperData} />
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-0'>
              Depois é só aproveitar todos os benefícios de ser Inter
            </p>
            <div className='btn-div'>
              {
                isMobile ? (
                  <Link
                    href={url}
                    title='Abra sua conta'
                    className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mb-0 mw-100'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        section_name: 'Como abrir sua conta digital pelo celular',
                        element_action: 'click button',
                        element_name: 'Abra sua conta',
                        redirect_url: url,
                      })
                    }}
                  >
                    Abra sua conta
                  </Link>
                ) : (
                  <OpenModal to='open-your-account-form'>
                    <button
                      className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mb-0 mw-100'
                      title='Abra sua conta'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_05',
                          section_name: 'Como abrir sua conta digital pelo celular',
                          element_action: 'click button',
                          element_name: 'Abra sua conta',
                        })
                      }}
                    >
                      Abra sua conta
                    </button>
                  </OpenModal>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default ComoAbrirSuaContaDigitalPeloCelular
