import React from 'react'

import { ContentHeader } from './style'

import ImageWebp from 'src/components/ImageWebp'

const PixPagamentosTransferencias = () => {
  return (
    <ContentHeader className='d-flex justify-content-center justify-content-lg-between'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 offset-xl-2 mb-4 mb-md-0 order-md-last'>
            <ImageWebp
              arrayNumbers={[ 300, 320, 396, 456 ]}
              arrayNumbersHeight={[ 300, 330, 394, 478 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra-3-cartoes/image.webp'
              altDescription='Mulher olhando celular'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-50 text-grayscale--500 fw-600 d-block mb-3'>
              Pagamentos e transferências gratuitas
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 mb-0'>
              Envie e receba dinheiro, faça pagamentos e compre de forma rápida e simples. Com Pix, TEDs e transferências no Inter,
              você faz agendamentos para todos os bancos sem pagar nada.
            </p>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default PixPagamentosTransferencias
